import React from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { connect } from 'react-redux'
import { hideLeftSidebar } from '../../../features/sidebar/LeftSidebarSlice'
import { Link } from 'react-router-dom'

const LeftSidebar = ({
    hideLeftSidebar,
    leftSidebarVisible,
    userData
}) => {
  return (
    <Offcanvas show={leftSidebarVisible}>
        <Offcanvas.Body>
            <div class="profileBox">
                <div class="image-wrapper">
                    <img src="assets/img/sample/avatar/avatar1.jpg" alt="image" class="imaged rounded" />
                </div>
                <div class="in">
                    <strong>{userData.UserName}</strong>
                    <div class="text-muted">
                        {userData.RoleID == 1 &&
                            <span>Super Admin</span>
                        }
                        {userData.RoleID == 2 &&
                            <span>Admin</span>
                        }
                        {userData.RoleID == 3 &&
                            <span>User</span>
                        }
                    </div>
                </div>
                <a href="javascript:void(0)" class="close-sidebar-button" onClick={() => {hideLeftSidebar()}}>
                    <ion-icon name="close"></ion-icon>
                </a>
            </div>
            <ul class="listview flush transparent no-line image-listview mt-5 pt-4">
                <li>
                    <Link to="/dashboard" onClick={() => {hideLeftSidebar()}} class="item p-0 pb-2">
                        <div class="icon-box bg-primary">
                            <ion-icon name="briefcase-outline"></ion-icon>
                        </div>
                        <div class="in">
                            Work
                        </div>
                    </Link>
                </li>
                <li>
                    <Link to="#!" class="item p-0 pb-2" onClick={() => {hideLeftSidebar()}}>
                        <div class="icon-box bg-primary">
                            <ion-icon name="chatbubbles-outline"></ion-icon>
                        </div>
                        <div class="in">
                            Messaging
                        </div>
                    </Link>
                </li>
            </ul>
        </Offcanvas.Body>
    </Offcanvas>
  )
}

const mapStateToProps = ({ leftSidebar, auth }) => {
    const {
        leftSidebarVisible
    } = leftSidebar
    const {
        userData
    } = auth
    return {
        leftSidebarVisible,
        userData
    }
}
  
const mapDispatchToProps = {
    hideLeftSidebar
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftSidebar)
