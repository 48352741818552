import { useState } from 'react';
// import { Link, useLocation } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import TaskForm from '../components/Pages/TaskForm';
// import jwt_decode from 'jwt-decode'

export function useAddTask() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const FormModal = () => {
        return <Modal show={show} size={'lg'} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className='h5'>Add Task</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TaskForm handleClose={handleClose} />
            </Modal.Body>
        </Modal>
    }

    const ModalButton = () => {
        return <Button className='position-fixed b-4 e-4 btn-icon btn-icon-only rounded p-3' style={{ 'zIndex': 10, height: '70px', width: '70px' }} title='Add Task' variant='primary' onClick={handleShow}>
            <span className='d-flex justify-content-center align-items-center' style={{ 'height': '45px' }}>
                <ion-icon name="add-outline" style={{ 'fontSize': '40px' }}></ion-icon>
            </span>
        </Button>
    }

    const ModalButtonDropdown = () => {
        return <span class="cursor-pointer d-flex align-items-center" onClick={handleShow}>
            <ion-icon name="add-outline"></ion-icon> Add Task
        </span>
    }

    return { FormModal, ModalButton, ModalButtonDropdown }
}