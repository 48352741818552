import React from 'react';

const Preloader = () => {
    return (
        <div class="d-flex justify-content-center align-items-center flex-column" style={{position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, background: '#00000042', zIndex: 999}}>
            <div class="spinner-border text-primary" role="status"></div>
            <span class="fs-4 mt-2 text-white">Loading...</span>
        </div>
    );
};

export default Preloader;