import React, { useState } from 'react'
import { Button, Offcanvas } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'
import { useAddTask } from '../../../Hooks/useAddTask'

const SidebarMenus = ({ userData }) => {
  return (
    <>
      <div className='py-0 bg-white h-100'>
        <div className="profileBox position-relative w-100">
          <div className="image-wrapper">
            <img src="assets/img/sample/avatar/avatar1.jpg" alt="image" className="imaged rounded" />
          </div>
          <div className="in">
            <strong>{userData?.UserName}</strong>
            {/* <div className="text-muted">
              Logged In
            </div> */}
          </div>
          <a href="#" className="close-sidebar-button d-block d-lg-none" data-bs-dismiss="offcanvas">
            <ion-icon name="close"></ion-icon>
          </a>
        </div>
        <ul className="listview link-listview">
          <li>
            <Link to="/taskList" className={(window.location.pathname.includes('taskList') || window.location.pathname === '/') ? "nav-link active bg-secondary" : "nav-link text-dark"}>
              Tasks
              {/* <span className="badge badge-secondary">52</span> */}
            </Link>
          </li>
          <li>
            <Link to="/loginLogout" className={(window.location.pathname.includes('loginLogout')) ? "nav-link active bg-secondary" : "nav-link text-dark"}>
              Attendance
              {/* <span className="text-muted">Edit</span> */}
            </Link>
          </li>
          <li>
            <Link to="/support" className={(window.location.pathname.includes('support')) ? "nav-link active bg-secondary" : "nav-link text-dark"}>
              Support
              {/* <span className="text-muted">Edit</span> */}
            </Link>
          </li>
        </ul>
      </div>
    </>
  )
}

export const ClockySidebarLayout = ({ children, userData }) => {
  const [show, setShow] = useState(false);
  const { FormModal, ModalButton } = useAddTask();

  const handleClose = () => setShow(false);
  const toggleShow = () => setShow((s) => !s);
  console.log(userData)
  return (
    <>
      <Offcanvas show={show} onHide={handleClose}>
        {/* <Offcanvas.Header closeButton>
          <Offcanvas.Title>Offcanvas</Offcanvas.Title>
        </Offcanvas.Header> */}
        <Offcanvas.Body className='p-0'>
          <SidebarMenus userData={userData} />
        </Offcanvas.Body>
      </Offcanvas>
      <Button className='btn-primary btn-icon btn-icon-only m-2 d-block d-lg-none' style={{ 'zIndex': 3 }} onClick={toggleShow}>
        <ion-icon name="menu-outline"></ion-icon>
      </Button>
      <div className='row'>
        <div className='col-lg-3 d-none d-lg-block'>
          <SidebarMenus userData={userData} />
        </div>
        <div className='col-lg-9 py-4' style={{ 'overflowY': 'auto', 'height': '100vh' }}>
          {children}
          <ModalButton />
          <FormModal />
        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({ auth }) => {
  const { userData } = auth
  return {
    userData
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ClockySidebarLayout)