export function useCalculateTaskTime() {
    function changeToLocal (dateTimeString) {
        // console.log(dateTimeString)
        const dateObject = new Date(dateTimeString);

// Format the date and time without milliseconds (YYYY-MM-DD HH:MM:SS)
        let formattedDate = dateObject.toISOString().slice(0, 16);
        formattedDate = formattedDate.split('T');
        formattedDate = `${formattedDate[0]} ${formattedDate[1]}`
        return formattedDate
    }
    async function convertTimeToSeconds(data) {
        // Check for format 1 (integer hours)
        // console.log(data);
        if (typeof data === "number" || !isNaN(data)) {
            return data * 3600;
        }

        // Check for format 2 (HH:MM)
        if (typeof data === "string" && data.includes(":")) {
            const [hours, minutes] = data.split(":").map(Number);
            return hours * 3600 + minutes * 60;
        }

        // Check for format 3 (HH.MM)
        if (typeof data === "string" && data.includes(".")) {
            const [hours, minutes] = data.split(".").map(parseFloat);
            // console.log(hours, minutes);
            return Math.floor(hours * 3600 + minutes * 60); // Round down to whole seconds
        }

        // console.log(data);
        return 0
        // Invalid format
        // throw new Error("Invalid time format. Please use integer hours (format 1), HH:MM (format 2), or HH.MM (format 3).");
    }

    async function secondsToTimeString(seconds) {
        // Check for valid input type (number)
        // console.log(seconds)
        if (typeof seconds !== "number" || isNaN(seconds)) {
          throw new Error("Input must be a number.");
        }
      
        // Get hours and remaining minutes (avoid negative values with Math.floor)
        const hours = Math.floor(seconds / 3600);
        const remainingMinutes = Math.floor(seconds % 3600 / 60);
        // console.log(hours, remainingMinutes)
        // Format hours and minutes with leading zeros (padStart)
        const formattedHours = hours.toString().padStart(2, "0");
        const formattedMinutes = remainingMinutes.toString().padStart(2, "0");
      
        // Return the formatted time string
        // console.log(`${formattedHours}.${formattedMinutes}`)
        return `${formattedHours}.${formattedMinutes}`;
    }

    const TotalWorkingTimeCalculation = (entries) => {
        let totalWorkingtime = 0;
        let currentStatus = null;
        let allEntries = JSON.parse(JSON.stringify(entries))

        let fromDate = null;
        // console.log('started')
        for (let i = 0; i < allEntries.length; i++) {


            currentStatus = allEntries[i].Status.toLowerCase();
            // console.log(fromDate);
            let difference = 0;
            if (currentStatus === 'started' || currentStatus === 'resumed' || currentStatus === 'ongoing' || fromDate === null) {
                // if(fromDate === null) {
                //     difference = (new Date().getTime() / 1000) - (new Date(changeToLocal(allEntries[i].DateTime)).getTime() / 1000);
                // } else {
                //     difference = (new Date(fromDate).getTime() / 1000) - (new Date(changeToLocal(allEntries[i].DateTime)).getTime() / 1000);
                // }
                if(fromDate !== null) {
                    const FromDate = new Date(fromDate);
                    const ToDate = new Date(changeToLocal(allEntries[i].DateTime));
                    difference = (ToDate.getTime() / 1000) - (FromDate.getTime() / 1000);
                    // console.log({
                    //     difference, 
                    //     i,
                    //     currentStatus,
                    //     FromDate,
                    //     ToDate
                    // }); 
                } else if((allEntries.length - 1 === i) && currentStatus === 'ongoing') {
                    const FromDate = new Date();
                    const ToDate = new Date(changeToLocal(allEntries[i].DateTime));
                    difference = (FromDate.getTime() / 1000) - (ToDate.getTime() / 1000);
                    // console.log({
                    //     difference, 
                    //     i,
                    //     currentStatus,
                    //     FromDate,
                    //     ToDate
                    // });
                } else {
                    // console.log({
                    //     difference, 
                    //     i,
                    //     currentStatus,
                    //     // FromDate,
                    //     // ToDate
                    // });
                }

                fromDate = changeToLocal(allEntries[i].DateTime);
            } else if (currentStatus === 'todo' || currentStatus === 'paused' || currentStatus === 'completed') {
                if((currentStatus === 'paused' || currentStatus === 'completed') && fromDate !== null) {
                    const FromDate = new Date(fromDate);
                    const ToDate = new Date(changeToLocal(allEntries[i].DateTime));
                    difference = (ToDate.getTime() / 1000) - (FromDate.getTime() / 1000);
                    // console.log({
                    //     difference, 
                    //     i,
                    //     currentStatus,
                    //     FromDate,
                    //     ToDate
                    //     // 'toDateS': new Date(changeToLocal(allEntries[i].DateTime)).getTime(),
                    //     // 'toDate' : new Date(changeToLocal(allEntries[i].DateTime)),
                    //     // 'fromDateS': new Date(fromDate).getTime(),
                    //     // 'fromDate': new Date(fromDate)
                    // });
                }
                fromDate = null;
            }
            totalWorkingtime = totalWorkingtime + difference;

        }
        // console.log(totalWorkingtime);
        return totalWorkingtime
    }

    const TaskProgressCalculation = async (task) => {
        const TempTask = JSON.parse(JSON.stringify(task));
        TempTask.ProgressBarData = {}
        const { ExpectedCompletionTime, Duration, entries } = TempTask;

        const MaxTime = '08.00';
        const TotalTimeWorkingInSeconds = TotalWorkingTimeCalculation(entries);
        const DurationInSeconds = await convertTimeToSeconds(Duration);
        let Stage1TimeInSeconds; // mostly expected time by admin
        let Stage2TimeInSeconds;
        let Stage3TimeInSeconds = await convertTimeToSeconds(MaxTime);

        // console.log(TotalTimeWorkingInSeconds);

        if (TotalTimeWorkingInSeconds !== undefined) {
            if (ExpectedCompletionTime) {
                const ExpectedCompletionTimeInSeconds = await convertTimeToSeconds(ExpectedCompletionTime);
                if (ExpectedCompletionTimeInSeconds > DurationInSeconds) {
                    Stage1TimeInSeconds = DurationInSeconds;
                    Stage2TimeInSeconds = ExpectedCompletionTimeInSeconds;
                    // console.log(Stage1TimeInSeconds, Stage2TimeInSeconds)
                } else {
                    Stage1TimeInSeconds = ExpectedCompletionTimeInSeconds;
                    Stage2TimeInSeconds = DurationInSeconds;
                    // console.log(Stage1TimeInSeconds, Stage2TimeInSeconds)
                }
            } else {
                Stage1TimeInSeconds = 0;
                Stage2TimeInSeconds = DurationInSeconds;
                // console.log(Stage1TimeInSeconds, Stage2TimeInSeconds)
            }

            
        
            // if (TempTask.ID === 175) {
                // console.log({ TempTask, ExpectedCompletionTime, entries, TotalTimeWorkingInSeconds, Stage1TimeInSeconds, Stage2TimeInSeconds })
            // }
            // TempTask.ProgressBarData.Stage1TimeInSeconds = Stage1TimeInSeconds;
            // TempTask.ProgressBarData.Stage2TimeInSeconds = Stage2TimeInSeconds;
            if (TotalTimeWorkingInSeconds <= Stage1TimeInSeconds) {
                TempTask.ProgressBarData.status = 'primary';
                TempTask.ProgressBarData.progress = Math.floor((TotalTimeWorkingInSeconds / Stage2TimeInSeconds) * 100);
            } else if (TotalTimeWorkingInSeconds >= Stage1TimeInSeconds && TotalTimeWorkingInSeconds <= Stage2TimeInSeconds) {
                TempTask.ProgressBarData.status = 'success';
                TempTask.ProgressBarData.progress = Math.floor((TotalTimeWorkingInSeconds / Stage2TimeInSeconds) * 100);
            } else {
                if (ExpectedCompletionTime) {
                    const ExpectedCompletionTimeInSeconds = await convertTimeToSeconds(ExpectedCompletionTime);
                    // console.log(TotalTimeWorkingInSeconds, ExpectedCompletionTimeInSeconds)

                    if (TotalTimeWorkingInSeconds <= ExpectedCompletionTimeInSeconds) {
                        TempTask.ProgressBarData.status = 'warning';
                        TempTask.ProgressBarData.progress = Math.floor((TotalTimeWorkingInSeconds / ExpectedCompletionTimeInSeconds) * 100);
                        // console.log(TotalTimeWorkingInSeconds)
                    } else {
                        TempTask.ProgressBarData.status = 'danger';
                        TempTask.ProgressBarData.progress = Math.floor((TotalTimeWorkingInSeconds / Stage3TimeInSeconds) * 100);
                        // console.log(TotalTimeWorkingInSeconds)
                    }
                } else {
                    TempTask.ProgressBarData.status = 'danger';
                    TempTask.ProgressBarData.progress = Math.floor((TotalTimeWorkingInSeconds / Stage3TimeInSeconds) * 100);
                    // console.log(TempTask, TotalTimeWorkingInSeconds, Stage2TimeInSeconds)
                    // console.log(TotalTimeWorkingInSeconds)
                }
            }
            // else if (TotalTimeWorkingInSeconds >= Stage2TimeInSeconds) {
            //     TempTask.ProgressBarData.status = 'danger';
            //     TempTask.ProgressBarData.progress = Math.floor((TotalTimeWorkingInSeconds / Stage3TimeInSeconds) * 100);
            //     console.log(TempTask, TotalTimeWorkingInSeconds, Stage2TimeInSeconds)
            // } 

            TempTask.ProgressBarData.TotalTimeTaken = await secondsToTimeString(TotalTimeWorkingInSeconds)
        }

        return TempTask

        // if(TempTask.Status.toLowerCase() === 'completed') {

        // } else if (TempTask.Status.toLowerCase() === 'started' || TempTask.Status.toLowerCase() === 'ongoing' || TempTask.Status.toLowerCase() === 'Resumed') {

        // } else if (TempTask.Status.toLowerCase() === 'todo') {

        // } else if (TempTask.Status.toLowerCase() === 'paused') {

        // }
    }

    return { convertTimeToSeconds, TotalWorkingTimeCalculation, TaskProgressCalculation, changeToLocal }
}