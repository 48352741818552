import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { hidePinModal } from '../../../features/modal/PinModalSlice';

const PinModal = ({ 
    pinModalVisible,
    hidePinModal
 }) => {
    const [pin, setPin] = useState(null)
    useEffect(() => {
        if (pin == '002024') {
            localStorage.setItem('verified', true)
            hidePinModal()
        }
    }, [pin])
    return (
        <Modal show={pinModalVisible} size={'sm'} backdrop={'static'} centered onHide={() => {hidePinModal()}}>
            <Modal.Body>
                <div class="login-form">
                    <div class="section">
                        <h1>OTP</h1>
                        <h4>We sent a verification code on your phone</h4>
                    </div>
                    <div class="section mt-2">
                        <form>

                            <div class="form-group boxed">
                                <div class="input-wrapper">
                                    <input type="text" class="form-control verify-input" id="smscode" placeholder="••••••" maxlength="6" value={pin} onChange={(e) => {setPin(e.target.value)}} />
                                </div>
                            </div>

                            {/* <div class="form-button-group position-relative">
                                <button type="submit" class="btn btn-primary btn-block btn-lg">Verify</button>
                            </div> */}

                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

const mapStateToProps = ({ pinModal }) => {
    const {
        pinModalVisible
    } = pinModal
    return {
        pinModalVisible
    }
}
  
const mapDispatchToProps = {
    hidePinModal
}

export default connect(mapStateToProps, mapDispatchToProps)(PinModal);