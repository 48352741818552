import React, { useState, useEffect } from 'react'
import axios from '../../../AxiosConfig'
import { connect } from 'react-redux'
import { setRenderCount, showPreloader } from '../../../features/data/dataSlice'
import moment from 'moment'

const TaskListView = ({
    taskList,
    setRenderCount,
    renderCount,
    userData,
    showPreloader,
    disabledAllButtons
}) => {
    const [secondsLeft, setSecondsLeft] = useState(0); // 1 minute countdown
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [showTimer, setShowTimer] = useState(false);
    const [showIcon, setShowIcon] = useState(true);
    const taskCreatedOn = moment(taskList.CreatedOn).fromNow();
    const userOnBreak = localStorage.getItem('break')
    const updateTaskStatus = (taskStatus, statusData) => {
        showPreloader({preloaderVisible: true})
        if (taskStatus == 'onGoing') {
            setSecondsLeft(60)
            setIsButtonEnabled(false)
            setShowIcon(false)
            setShowTimer(true)
        }
        const moment = require('moment');
        const date = moment();
        const formattedDate = date.format('YYYY-MM-DD');
        const formattedDateTime = date.format('YYYY-MM-DD HH:mm:ss');
        const taskData = {
            'UserID': statusData.UserID,
            'Status': taskStatus,
            'DateTime': formattedDateTime,
            'Date': formattedDate,
            'Duration': statusData.Duration,
            'ProjectID': statusData.ProjectID,
            'TaskID': statusData.ID,
            'Description': statusData.Description,
            'TaskName': statusData.TaskName,
            'UserName': statusData.UserName,
            'ProjectName': statusData.ProjectName,
            'Break': (userOnBreak == true || userOnBreak == 'true') ? true : false 
        }
        axios.put('/entries', taskData).then(res => {
            if (res.data.message === 'Success') {
                setRenderCount({renderCount: parseInt(renderCount) + 1})
            }
        })
    }

    useEffect(() => {
        if (secondsLeft > 0) {
        const timerId = setTimeout(() => {
            setSecondsLeft(secondsLeft - 1);
        }, 1000);
        return () => clearTimeout(timerId);
        } else {
        setIsButtonEnabled(true);
        setShowIcon(true)
        setShowTimer(false)
        }
    }, [secondsLeft]);
    return (
        <>
            {taskList.TaskName !== 'BREAK' &&
                <li>
                    <div class="item pe-0 ps-0">
                        {taskList.UserID == userData.ID ?
                            <>
                                {(taskList.Status == 'paused' || taskList.Status == 'toDo') &&
                                    <button class="icon-box border-warning me-1 cursor-pointer" style={{minHeight: '45px', minWidth: '45px'}} onClick={() => { updateTaskStatus('onGoing', taskList) }} disabled={disabledAllButtons}>
                                        <ion-icon name="play" class="text-warning"></ion-icon>
                                    </button>
                                }
                                {(taskList.Status == 'onGoing') &&
                                    <button class="icon-box border-primary me-1 cursor-pointer" style={{minHeight: '45px', minWidth: '45px'}} onClick={() => { updateTaskStatus('paused', taskList) }} disabled={disabledAllButtons}>
                                        <ion-icon name="pause" class="text-primary"></ion-icon>
                                    </button>
                                }
                            </>
                        :
                            <>
                                {(taskList.Status == 'paused' || taskList.Status == 'toDo') &&
                                    <div class="icon-box border-warning me-1 cursor-pointer" style={{minHeight: '45px', minWidth: '45px'}}>
                                        <ion-icon name="play" class="text-warning"></ion-icon>
                                    </div>
                                }
                                {(taskList.Status == 'onGoing') &&
                                    <div class="icon-box border-primary me-1 cursor-pointer" style={{minHeight: '45px', minWidth: '45px'}}>
                                        <ion-icon name="pause" class="text-primary"></ion-icon>
                                    </div>
                                }
                            </>
                        }
                        {taskList.Status == 'completed' &&
                            <div class="icon-box border-success me-1" style={{minHeight: '45px', minWidth: '45px'}}>
                                <ion-icon name="checkmark-done" class="text-success"></ion-icon>
                            </div>
                        }
                        <div class="in pb-2" style={{display: 'initial', position: 'relative'}}>
                            <div class="row d-flex align-items-center">
                                <div class="col-md-8">
                                    {taskList.TaskName}
                                    <div class="d-flex align-items-center">
                                        <div class="fs-10"><ion-icon name="person" class="fs-10"></ion-icon> {taskList.Duration} hrs {(taskList.ExpectedCompletionTime) ? <>| <span class="text-warning"><i> {taskList.ExpectedCompletionTime} Hour(s)</i></span></> : ('')}</div>&nbsp;.&nbsp;<span class="fs-10 fw-bold">{taskList.ProjectName}</span>
                                    </div>
                                </div>
                                <div class="col-md-4 text-end">
                                    {taskList.UserID == userData.ID &&
                                        <>
                                            {(taskList.Status == 'onGoing') &&
                                                <button class="btn border-primary me-1 cursor-pointer" onClick={() => { updateTaskStatus('completed', taskList) }} style={{borderWidth: '2px'}} disabled={(disabledAllButtons) ? disabledAllButtons : !isButtonEnabled}>
                                                    {showIcon &&
                                                        <ion-icon name="checkmark" class="me-0"></ion-icon>
                                                    }
                                                    {showTimer &&
                                                        <> 
                                                            {secondsLeft} seconds
                                                        </>
                                                    }
                                                </button>
                                            }
                                        </>
                                    }
                                    {/* {taskList.Status == 'completed' &&
                                        <span class="badge bg-success">Completed</span>
                                    } */}
                                </div>
                            </div>
                            <span class="fs-10"  style={{position: 'absolute', bottom: 0}}>{taskCreatedOn}</span>
                        </div>
                    </div>
                </li>
            }
        </>
    );
};

const mapStateToProps = ({ data, auth }) => {
    const {
        renderCount,
        disabledAllButtons
    } = data
    const {
        userData
    } = auth
    return {
        renderCount,
        userData,
        disabledAllButtons
    }
}
  
const mapDispatchToProps = {
    setRenderCount,
    showPreloader
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskListView);