import React from 'react'
import { Link } from 'react-router-dom'

function Dashboard({
    
}) {
    return (
        <div class="d-flex justify-content-center align-items-center flex-column" style={{height: '100vh'}}>
            <img src="https://webweb.ams3.cdn.digitaloceanspaces.com/data/skyqtech.webweb.ai.in/SkyQ-Tech-logo-Horizontal.svg" alt="" style={{width: '200px', position: 'fixed', top: 50}} />
            <div class="mt-5">
                <Link to="/loginLogout" class="btn btn-secondary btn-lg me-2">
                    Login / Logout
                </Link>
                <Link to="/addTask" class="btn btn-success btn-lg">
                    Add Tasks
                </Link>
            </div>
        </div>
    )
}

export default Dashboard
