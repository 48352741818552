import React, { useState, useEffect } from 'react';
import axios from '../../AxiosConfig';
import Navigation from './smallComponents/Navigation';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { setUsersList, setTasksList, setProjectsList } from '../../features/data/dataSlice';
import { Button, Modal, ProgressBar } from 'react-bootstrap';
import { useCalculateTaskTime } from '../../Hooks/useCalculateTaskTime';



const ProgressComp = ({ userDuration = '00:00', expectedDuration = '00:00', currentStatus = null, entries = [], ProgressBarData = {} }) => {

    // if(currentStatus.toLowerCase() === 'completed') {
    //     const userExptTime = convertTimeToSeconds(userDuration);
    //     if(expectedDuration) {
    //         const totalExptTime = convertTimeToSeconds(expectedDuration);
    //         // console.log(userExptTime, totalExptTime);
    //     }
    //     return <ProgressBar>
    //     <ProgressBar striped variant="warning" now={100} key={2} />
    // </ProgressBar>
    // }
    if (ProgressBarData.progress === undefined) {
        return <></>
    }
    // console.log(ProgressBarData);
    return <>
        <ProgressBar className='TaskProgress'>
            {
                (currentStatus.toLowerCase() === 'started' || currentStatus.toLowerCase() === 'ongoing' || currentStatus.toLowerCase() === 'resumed') &&
                    <ProgressBar striped animated variant={ProgressBarData.status} now={ProgressBarData.progress > 99 ? 100 : ProgressBarData.progress} key={1} />
            }
            {
                (currentStatus.toLowerCase() === 'paused' || currentStatus.toLowerCase() === 'todo') &&
                    <ProgressBar striped variant={ProgressBarData.status} now={ProgressBarData.progress > 99 ? 100 : ProgressBarData.progress} key={1} />
            }
            {
                currentStatus.toLowerCase() === 'completed' &&
                    <ProgressBar variant={ProgressBarData.status} now={ProgressBarData.progress > 99 ? 100 : ProgressBarData.progress} key={1} />
            }
            {/* <ProgressBar variant="warning" now={20} key={2} />
            <ProgressBar striped variant="danger" now={10} key={3} /> */}
        </ProgressBar>
    </>
}

const TaskList = ({
    setUsersList,
    usersList,
    userRole,
    setTasksList,
    taskList,
    setProjectsList,
    projectsList,
    refresh
}) => {
    const filterId = null;
    const { TaskProgressCalculation } = useCalculateTaskTime();
    const [userList, setUserList] = useState(null)
    const [projectList, setProjectList] = useState(null)
    const [userName, setUserName] = useState('')
    const [userID, setUserID] = useState(null)
    const [date, setDate] = useState(null)
    const [projectName, setProjectName] = useState(null)
    const [projectID, setProjectID] = useState(null)
    const [status, setStatus] = useState(null)
    const [renderCount, setRenderCount] = useState(0)


    useEffect(() => {
        // axios.get('/projects').then(res=>{
        //     if (res.data.message === 'Success') {
        //         setProjectList(res.data.data)
        //     }
        // })
        axios.get('/tasks').then(res => {
            if (res.data.message === 'Success') {
                let taskResponse = JSON.parse(JSON.stringify(res.data.data))
                taskResponse = sortArray(taskResponse, 'Date')
                // taskResponse = [
                //     {
                //         "ID": 42,
                //         "CreatedBy": 1,
                //         "TaskName": "simmcpgdm.org ",
                //         "UserID": 19,
                //         "Status": "Completed",
                //         "ExpectedCompletionTime": null,
                //         "Date": "2024-05-29",
                //         "Duration": "3",
                //         "ProjectID": 2,
                //         "Priority": null,
                //         "entries": [
                //             {
                //                 "ID": 72,
                //                 "CreatedBy": 1,
                //                 "TaskID": "42",
                //                 "UserID": 19,
                //                 "Status": "Started",
                //                 "Description": "",
                //                 "DateTime": "2024-05-29T12:55:46.000Z"
                //             },
                //             {
                //                 "ID": 76,
                //                 "CreatedBy": 1,
                //                 "TaskID": "42",
                //                 "UserID": 19,
                //                 "Status": "Paused",
                //                 "Description": "",
                //                 "DateTime": "2024-05-29T15:03:45.000Z"
                //             },
                //             {
                //                 "ID": 97,
                //                 "CreatedBy": 1,
                //                 "TaskID": "42",
                //                 "UserID": 19,
                //                 "Status": "Resumed",
                //                 "Description": "",
                //                 "DateTime": "2024-05-31T14:27:47.000Z"
                //             },
                //             {
                //                 "ID": 101,
                //                 "CreatedBy": 1,
                //                 "TaskID": "42",
                //                 "UserID": 19,
                //                 "Status": "Completed",
                //                 "Description": "",
                //                 "DateTime": "2024-05-31T16:19:09.000Z"
                //             }
                //         ],
                //         "ProgressBarData": {}
                //     }
                // ]
                if (filterId) taskResponse = taskResponse.filter((task) => task.ID == filterId);
                Promise.all(taskResponse.map((item) => TaskProgressCalculation(item)))
                    .then((taskProgresses) => {
                        setTasksList({ taskList: taskProgresses })
                    })
                    .catch((error) => {
                        console.error(error); // Handle any errors
                    });
                // taskResponse = taskResponse.map((item) => {
                //     const taskProgress = TaskProgressCalculation(item)
                //     return taskProgress
                // })
                // console.log(taskResponse)
                // setTasksList({taskList: taskResponse})
            }
        })

        axios.get('/users').then(res => {
            if (res.data.message === 'Success') {
                setUserList(res.data.data)
                setUsersList({ usersList: res.data.data })
                // taskResponse = taskResponse.map((v, k) => {
                //     let newValue = JSON.parse(JSON.stringify(v))
                //     res.data.data.map((v1, k1) => {
                //         if (v.UserID == v1.ID) {
                //             newValue.UserName = v1.UserName
                //         }
                //     })
                //     // console.log(newValue)
                //     return newValue
                // })
            }
        })
        axios.get('/projects').then(res => {
            if (res.data.message === 'Success') {
                setProjectList(res.data.data)
                setProjectsList({ projectsList: res.data.data })
                // taskResponse = taskResponse.map((v, k) => {
                //     let newValue = JSON.parse(JSON.stringify(v))
                //     res.data.data.map((v1, k1) => {
                //         // console.log(v1)
                //         if (v.ProjectID == v1.ID) {
                //             newValue.ProjectName = v1.Name
                //             // console.log(v1.Name)
                //         }
                //     })
                //     return newValue
                // })
            }
        })
    }, [renderCount])
    const updateUserNameFilter = (userData) => {
        setUserName(userData.UserName)
        setUserID(userData.ID)
    }
    const updateDateFilter = (date) => {
        setDate(date)
    }
    const updateProjectFilter = (projectData) => {
        setProjectName(projectData.Name)
        setProjectID(projectData.ID)
    }
    const updateTaskStatusFilter = (status) => {
        setStatus(status)
    }

    function GetFilteredData () {
        const filterData = {
            'UserID': (userID) ? userID : null,
            'Date': (date) ? date : null,
            'ProjectID': (projectID) ? projectID : null,
            'Status': (status) ? status : null
        }
        axios.post('/tasks/filter', filterData).then(res => {
            if (res.data.message === 'Success') {
                let taskResponse = JSON.parse(JSON.stringify(res.data.data))
                // taskResponse.map((v, k) => {
                //     usersList?.map((v1, k1) => {
                //         if (v.UserID == v1.ID) {
                //             taskResponse[k].UserName = v1.UserName
                //         }
                //     })
                //     projectsList?.map((v1, k1) => {
                //         if (v.ProjectID == v1.ID) {
                //             taskResponse[k].ProjectName = v1.Name
                //         }
                //     })
                // })
                let newArr = sortArray(taskResponse, 'Date')
                // console.log(newArr, 'one')
    
                // setTasksList({taskList:newArr})
                if (filterId) newArr = newArr.filter((task) => task.ID == filterId);
    
                Promise.all(newArr.map((item) => TaskProgressCalculation(item)))
                    .then((taskProgresses) => {
                        setTasksList({ taskList: taskProgresses })
                        // console.log(taskProgresses); // Array of task progress values
                    })
                    .catch((error) => {
                        console.error(error); // Handle any errors
                    });
            }
        })
    }
    useEffect(() => {
        if(userID || date || projectID || status) {
            GetFilteredData()
        }
    }, [userID, date, projectID, status]);

    useEffect(() => {
        if(refresh !== 0) {
            GetFilteredData()
        }
    }, [refresh])
    

    const updateTaskStatus = (taskStatus, statusData) => {
        // setSubmitButtonDisabled(true)
        const moment = require('moment');
        const date = moment();
        const formattedDate = date.format('YYYY-MM-DD');
        const formattedDateTime = date.format('YYYY-MM-DD HH:mm:ss');
        const taskData = {
            'UserID': statusData.UserID,
            'Status': taskStatus,
            'DateTime': formattedDateTime,
            'Date': formattedDate,
            'Duration': statusData.Duration,
            'ProjectID': statusData.ProjectID,
            'TaskID': statusData.ID,
            'Description': statusData.Description,
            'TaskName': statusData.TaskName,
            'UserName': statusData.UserName,
            'ProjectName': statusData.ProjectName
        }
        axios.put('/entries', taskData).then(res => {
            if (res.data.message === 'Success') {
                setRenderCount(renderCount + 1)
            }
        })
    }

    function sortArray(array, key, order = 'descending') {
        return array.sort((a, b) => {
            let comparison = 0;

            if (a[key] > b[key]) {
                comparison = 1;
            } else if (a[key] < b[key]) {
                comparison = -1;
            }

            return order === 'descending' ? comparison * -1 : comparison;
        });
    }

    const updateTaskName = (taskName, index) => {
        let tempTaskData = JSON.parse(JSON.stringify(taskList))
        tempTaskData[index].TaskName = taskName

        // setTasksList({taskList:tempTaskData})
        Promise.all(tempTaskData.map((item) => TaskProgressCalculation(item)))
            .then((taskProgresses) => {
                setTasksList({ taskList: taskProgresses })
            })
            .catch((error) => {
                console.error(error); // Handle any errors
            });
    }

    const updateDuration = (taskDuration, index) => {
        let tempTaskData = JSON.parse(JSON.stringify(taskList))
        tempTaskData[index].Duration = taskDuration

        // setTasksList({taskList:tempTaskData})
        Promise.all(tempTaskData.map((item) => TaskProgressCalculation(item)))
            .then((taskProgresses) => {
                setTasksList({ taskList: taskProgresses })
            })
            .catch((error) => {
                console.error(error); // Handle any errors
            });
    }

    const updateExpectedDuration = (taskExpectedDuration, index) => {
        let tempTaskData = JSON.parse(JSON.stringify(taskList))
        tempTaskData[index].ExpectedCompletionTime = taskExpectedDuration

        // setTasksList({taskList:tempTaskData})
        Promise.all(tempTaskData.map((item) => TaskProgressCalculation(item)))
            .then((taskProgresses) => {
                setTasksList({ taskList: taskProgresses })
            })
            .catch((error) => {
                console.error(error); // Handle any errors
            });
    }

    const updatePriority = (taskPriority, index) => {
        let tempTaskData = JSON.parse(JSON.stringify(taskList))
        tempTaskData[index].Priority = taskPriority

        // setTasksList({taskList:tempTaskData})
        Promise.all(tempTaskData.map((item) => TaskProgressCalculation(item)))
            .then((taskProgresses) => {
                setTasksList({ taskList: taskProgresses })
            })
            .catch((error) => {
                console.error(error); // Handle any errors
            });
    }

    const updateUserName = (taskUserName, index) => {
        let tempTaskData = JSON.parse(JSON.stringify(taskList))
        tempTaskData[index].UserName = taskUserName
        usersList.map((v, k) => {
            if (v.UserName == taskUserName) {
                tempTaskData[index].UserID = v.ID
            }
        })

        // setTasksList({taskList:tempTaskData})
        Promise.all(tempTaskData.map((item) => TaskProgressCalculation(item)))
            .then((taskProgresses) => {
                setTasksList({ taskList: taskProgresses })
            })
            .catch((error) => {
                console.error(error); // Handle any errors
            });
    }

    const updateTask = (index) => {
        let updatedTask = JSON.parse(JSON.stringify(taskList[index]))
        updatedTask.Priority = (updatedTask.Priority) ? updatedTask.Priority : 'low'
        axios.post('/tasks/update/' + taskList[index].ID, updatedTask).then(res => {
            if (res.data.message === 'Success') {
                setProjectList(res.data.data)
                setRenderCount(renderCount + 1)
            }
        })
    }
    return (
        <div class="container d-flex align-items-center flex-column justify-content-center mt-1">
            {/* <span class="mb-3 mt-3">
                <Navigation></Navigation>
            </span> */}
            <div class="section d-flex flex-column mb-2 w-100">
                <div class="row">
                    <div class="col-4 col-md-4">
                        <div class="dropdown mb-1">
                            <button class="btn btn-secondary btn-block btn-sm dropdown-toggle pe-0 ps-1" type="button" data-bs-toggle="dropdown">
                                {(userName) ? userName : 'Select User Name'} <ion-icon name="chevron-down-outline" class="fs-6" style={{ paddingTop: '2px' }}></ion-icon>
                            </button>
                            <div class="dropdown-menu p-0">
                                {userList?.map((v, k) => {
                                    return <a class="dropdown-item" href="javascript:void(0)" key={k} onClick={() => { updateUserNameFilter(v) }}>{v.UserName}</a>
                                })}
                            </div>
                        </div>
                    </div>
                    <div class="col-4 col-md-4">
                        <div class="dropdown mb-1">
                            <button class="btn btn-secondary btn-sm btn-block dropdown-toggle pe-0 ps-1" type="button" data-bs-toggle="dropdown">
                                {(projectName) ? projectName : 'Select Project Name'} <ion-icon name="chevron-down-outline" class="fs-6" style={{ paddingTop: '2px' }}></ion-icon>
                            </button>
                            <div class="dropdown-menu p-0">
                                {projectList?.map((v, k) => {
                                    return <a class="dropdown-item" href="javascript:void(0)" key={k} onClick={() => { updateProjectFilter(v) }}>{v.Name}</a>
                                })}
                            </div>
                        </div>
                    </div>
                    <div class="col-4 col-md-4">
                        <div class="dropdown mb-1">
                            <button class="btn btn-secondary btn-sm btn-block dropdown-toggle pe-0 ps-1" type="button" data-bs-toggle="dropdown">
                                {(status) ? status : 'Select Status'} <ion-icon name="chevron-down-outline" class="fs-6" style={{ paddingTop: '2px' }}></ion-icon>
                            </button>
                            <div class="dropdown-menu p-0">
                                <a class="dropdown-item" href="javascript:void(0)" onClick={() => { updateTaskStatusFilter('toDo') }}>To Do</a>
                                <a class="dropdown-item" href="javascript:void(0)" onClick={() => { updateTaskStatusFilter('onGoing') }}>On Going</a>
                                <a class="dropdown-item" href="javascript:void(0)" onClick={() => { updateTaskStatusFilter('pasued') }}>Paused</a>
                                <a class="dropdown-item" href="javascript:void(0)" onClick={() => { updateTaskStatusFilter('completed') }}>Completed</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-md-6">
                        <div class="form-group boxed p-0 mb-1">
                            <div class="input-wrapper">
                                <label class="form-label" for="fromDate">From</label>
                                <input type="date" class="form-control pe-1" placeholder="Enter your name" autocomplete="off" value={date} onChange={(e) => { updateDateFilter(e.target.value) }} style={{ height: '32px' }} />
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-md-6">
                        <div class="form-group boxed p-0 mb-1">
                            <div class="input-wrapper">
                                <label class="form-label" for="toDate">To</label>
                                <input type="date" class="form-control pe-1" placeholder="Enter your name" autocomplete="off" value={date} onChange={(e) => { updateDateFilter(e.target.value) }} style={{ height: '32px' }} />
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pb-5">
                    {(taskList?.length > 0) &&
                        <>
                            {userRole === 'admin' ?
                                <div class="table-responsive border">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="fw-bold text-nowrap">Task Action</th>
                                                <th scope="col" class="fw-bold text-nowrap">Task</th>
                                                {/* <th scope="col" class="fw-bold text-nowrap">Project</th> */}
                                                <th scope="col" class="fw-bold text-nowrap">Duration (hrs)</th>
                                                {(userRole == 'admin') &&
                                                    <>
                                                        <th scope="col" class="fw-bold text-nowrap">Expected Time (hrs)</th>
                                                        <th scope="col" class="fw-bold text-nowrap">Priority</th>
                                                    </>
                                                }
                                                {(userRole == 'admin') &&
                                                    <>
                                                        <th scope="col" class="fw-bold text-nowrap">Username</th>
                                                        <th scope="col" class="fw-bold text-nowrap">Action</th>
                                                    </>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {taskList?.map((v, k) => {
                                                return <React.Fragment key={k}>
                                                    {(k == 0 || (v.Date !== taskList[k - 1]?.Date)) &&
                                                        <tr class="bg-secondary">
                                                            <td colSpan={(userRole == 'admin') ? '7' : '4'} class="fw-bold text-center">
                                                                <Moment format="DD MMMM YYYY">{v.Date}</Moment>
                                                            </td>
                                                        </tr>
                                                    }
                                                    {/* {(v.Status !== 'Completed' && v.Status !== 'completed') && */}
                                                    <>
                                                        <tr>
                                                            <td class={"text-nowrap"}>
                                                                {(userRole != 'admin') &&
                                                                    <>
                                                                        {(v.Status == 'paused' || v.Status == 'toDo') &&
                                                                            <button class="btn btn-outline-primary btn-sm me-1" onClick={() => { updateTaskStatus('onGoing', v) }}>
                                                                                <ion-icon name="play" class="me-0"></ion-icon>
                                                                            </button>
                                                                        }
                                                                        {(v.Status == 'onGoing') &&
                                                                            <>
                                                                                <button class="btn btn-outline-primary btn-sm me-1" onClick={() => { updateTaskStatus('paused', v) }}>
                                                                                    <ion-icon name="pause" class="me-0"></ion-icon>
                                                                                </button>
                                                                            </>
                                                                        }
                                                                        {(v.Status == 'onGoing') &&
                                                                            <>
                                                                                <button class="btn btn-outline-primary btn-sm me-1" onClick={() => { updateTaskStatus('completed', v) }}>
                                                                                    <ion-icon name="checkmark" class="me-0"></ion-icon>
                                                                                </button>
                                                                            </>
                                                                        }
                                                                    </>
                                                                }
                                                                {(userRole == 'admin') &&
                                                                    <>
                                                                        <button class="btn btn-outline-primary btn-sm me-1" onClick={() => { updateTaskStatus('toDo', v) }}>
                                                                            <ion-icon name="list" class="me-0"></ion-icon>
                                                                        </button>
                                                                        <button class="btn btn-outline-primary btn-sm me-1" onClick={() => { updateTaskStatus('onGoing', v) }}>
                                                                            <ion-icon name="play" class="me-0"></ion-icon>
                                                                        </button>
                                                                        <button class="btn btn-outline-primary btn-sm me-1" onClick={() => { updateTaskStatus('paused', v) }}>
                                                                            <ion-icon name="pause" class="me-0"></ion-icon>
                                                                        </button>
                                                                        <button class="btn btn-outline-primary btn-sm me-1" onClick={() => { updateTaskStatus('completed', v) }}>
                                                                            <ion-icon name="checkmark" class="me-0"></ion-icon>
                                                                        </button>
                                                                    </>
                                                                }
                                                                <>
                                                                    {/* {v.Status == 'toDo' &&
                                                                            <span class="badge bg-primary text-white me-1">To Do</span>
                                                                        }
                                                                        {v.Status == 'onGoing' &&
                                                                            <span class="badge bg-warning text-white me-1">On Going</span>
                                                                        }
                                                                        {v.Status == 'paused' &&
                                                                            <span class="badge bg-secondary text-white me-1">Paused</span>
                                                                        } */}
                                                                    {v.Status == 'completed' &&
                                                                        <span class="badge bg-success text-white me-1">Completed</span>
                                                                    }

                                                                    {/* {
                                                                            v.Status
                                                                        }
                                                                        {
                                                                            v.ID
                                                                        } */}
                                                                </>
                                                            </td>
                                                            <td>
                                                                <div class="d-flex" style={{ width: '250px' }}>
                                                                    {(v.Priority == 'high') &&
                                                                        <span class="badge me-1" style={{ background: '#e82525', minWidth: 'auto', height: '16px', fontSize: '10px' }}>High</span>
                                                                    }
                                                                    {
                                                                        (v.Priority == '' || v.Priority == null || v.Priority == undefined || v.Priority == 'low') &&
                                                                        <span class="badge me-1 d-block" style={{ minWidth: 'auto', 'width': '34px', height: '16px', fontSize: '10px' }}></span>
                                                                    }
                                                                    {/* {v.Priority == 'medium' &&
                                                                            <span class="badge me-1" style={{background: '#de5b5b'}}>Medium</span>
                                                                        } */}
                                                                    {/* {v.Priority == 'low' &&
                                                                            <span class="badge me-1" style={{background: '#db8f8f', minWidth: 'auto', height: '16px', fontSize: '10px'}}>Low</span>
                                                                        } */}
                                                                    {(userRole != 'admin') &&
                                                                        <div class="d-flex flex-column">
                                                                            <h4 className='mb-0'>{v.TaskName}</h4>
                                                                            <div className='d-flex gap-2 align-items-center mt-1'>
                                                                                <h5 className='mb-0' style={{ 'fontWeight': '700' }}>
                                                                                    {v.UserName}
                                                                                </h5>

                                                                                <span class="fs-10 badge badge-secondary w-fit" style={{ 'width': 'fit-content' }}>{v.ProjectName}</span>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {(userRole == 'admin') &&
                                                                        <>
                                                                            <div class="form-group boxed p-0">
                                                                                <div class="input-wrapper">
                                                                                    <textarea class="form-control pe-1 ps-1" id="taskName" placeholder="Enter Task" value={v.TaskName} onChange={(e) => { updateTaskName(e.target.value, k) }} style={{ fontSize: '13px' }} rows={'2'}
                                                                                    ></textarea>
                                                                                    <i class="clear-input">
                                                                                        <ion-icon name="close-circle"></ion-icon>
                                                                                    </i>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                </div>
                                                            </td>
                                                            {/* <td class="text-nowrap">
                                                                    <>
                                                                        {v.ProjectName}
                                                                    </>
                                                                </td> */}
                                                            <td>
                                                                {(userRole != 'admin') &&
                                                                    <>
                                                                        <div className='d-flex justify-content-between'>
                                                                            <span title='Duration' className='text-primary'>
                                                                                {v.Duration} Hour(s)
                                                                            </span>

                                                                            <span title='Expected Time' className='text-warning'>
                                                                                {(v.ExpectedCompletionTime) ? <i>{('' + v.ExpectedCompletionTime + ' Hour(s) ')}</i> : ''}
                                                                            </span>
                                                                        </div>
                                                                        <ProgressComp userDuration={v.Duration} expectedDuration={v.ExpectedCompletionTime} currentStatus={v.Status} entries={v.entries} ProgressBarData={v.ProgressBarData} />
                                                                        {
                                                                            v.Status.toLowerCase() === 'completed' &&
                                                                            <div className='d-flex'>
                                                                                <span title='Completed In' className='text-success'>
                                                                                    {(v?.ProgressBarData?.TotalTimeTaken) ? <i>{('' + v.ProgressBarData?.TotalTimeTaken + ' Hour(s) ')}</i> : ''}
                                                                                </span>
                                                                            </div>
                                                                        }
                                                                    </>
                                                                }
                                                                {(userRole == 'admin') &&
                                                                    <div class="form-group boxed p-0">
                                                                        <div class="input-wrapper">
                                                                            <input type="text" pattern="\d{2}.\d{2}" class="form-control pe-1 ps-1" id="duration" value={v.Duration} placeholder="Duration" onChange={(e) => { updateDuration(e.target.value, k) }} style={{ height: '24px', fontSize: '13px' }} />
                                                                            <i class="clear-input">
                                                                                <ion-icon name="close-circle"></ion-icon>
                                                                            </i>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </td>
                                                            {(userRole == 'admin') &&
                                                                <>
                                                                    <td>
                                                                        <div class="form-group boxed p-0">
                                                                            <div class="input-wrapper">
                                                                                <input type="text" pattern="\d{2}.\d{2}" class="form-control pe-1 ps-1" id="phone5" value={v.ExpectedCompletionTime}
                                                                                    onChange={(e) => { updateExpectedDuration(e.target.value, k) }} placeholder="Expected duration" style={{ height: '24px', fontSize: '13px' }} />
                                                                                <i class="clear-input">
                                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                                </i>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="form-group boxed p-0" style={{ width: '75px' }}>
                                                                            <div class="input-wrapper">
                                                                                <select class="form-control form-select pe-3 ps-1" value={v.Priority} onChange={(e) => { updatePriority(e.target.value, k) }} id="priority" style={{ height: '24px', fontSize: '13px' }}>
                                                                                    <option value="high">High</option>
                                                                                    {/* <option value="medium">Medium</option> */}
                                                                                    <option value="low">Low</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </>
                                                            }
                                                            {(userRole == 'admin') &&
                                                                <>
                                                                    <td class="text-nowrap">
                                                                        {(userRole == 'admin') &&
                                                                            <div class="form-group boxed p-0" style={{ width: '75px' }}>
                                                                                <div class="input-wrapper">
                                                                                    <select class="form-control form-select pe-3 ps-1" value={v.UserName} onChange={(e) => { updateUserName(e.target.value, k) }} id="priority" style={{ height: '24px', fontSize: '13px' }}>
                                                                                        {userList?.map((v, k) => {
                                                                                            return <option value={v.UserID}>{v.UserName}</option>
                                                                                        })}
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        <button class="btn btn-sm btn-success" onClick={() => { updateTask(k) }} >
                                                                            Update</button>
                                                                    </td>
                                                                </>
                                                            }
                                                        </tr>
                                                    </>
                                                    {/* } */}
                                                </React.Fragment>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <>
                                     <ul class="listview simple-listview">
                                     {taskList?.map((v, k) => {
                                        return <React.Fragment key={k}>
                                                    {(k == 0 || (v.Date !== taskList[k - 1]?.Date)) &&
                                                    <>
                                                        <div class="listview-title bg-secondary"><Moment format="DD MMMM YYYY">{v.Date}</Moment></div>
                                                    </>
                                                    }
                                                    <li>
                                                        <div class="item py-2 w-100">
                                                            <div class="in">
                                                                <div className='text-dark'>
                                                                    <div className='d-flex justify-content-between'>
                                                                        <div className='TasksInfo'>
                                                                            {v.TaskName}
                                                                            <br />
                                                                                <span
                                                                                className='d-none'
                                                                                >
                                                                                    {
                                                                                        v.Status
                                                                                    }
                                                                                    |
                                                                                    {
                                                                                        v.ID
                                                                                    }
                                                                                </span>
                                                                            <div className='d-flex gap-2 mt-1 align-items-center'>
                                                                                <header>{v.UserName}</header>
                                                                                <span class="fs-10 badge badge-secondary w-fit" style={{ 'width': 'fit-content' }}>{v.ProjectName}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className='TasksActions d-flex justify-content-end flex-wrap'>
                                                                            {(v.Status == 'paused' || v.Status == 'toDo') &&
                                                                                <button class="btn btn-outline-primary btn-sm me-1" onClick={() => { updateTaskStatus('onGoing', v) }}>
                                                                                    <ion-icon name="play" class="me-0"></ion-icon>
                                                                                </button>
                                                                            }
                                                                            {(v.Status == 'onGoing') &&
                                                                                <>
                                                                                    <button class="btn btn-outline-primary btn-sm me-1" onClick={() => { updateTaskStatus('paused', v) }}>
                                                                                        <ion-icon name="pause" class="me-0"></ion-icon>
                                                                                    </button>
                                                                                </>
                                                                            }
                                                                            {(v.Status == 'onGoing') &&
                                                                                <>
                                                                                    <button class="btn btn-outline-primary btn-sm me-1" onClick={() => { updateTaskStatus('completed', v) }}>
                                                                                        <ion-icon name="checkmark" class="me-0"></ion-icon>
                                                                                    </button>
                                                                                </>
                                                                            }
                                                                            {v.Status == 'completed' &&
                                                                                <span class="badge bg-success text-white me-1">Completed</span>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div className='d-flex justify-content-end gap-2'>
                                                                            <span title='Duration' className='text-primary fs-10'>
                                                                                {v.Duration} Hour(s)
                                                                            </span>
                                                                            {
                                                                                v.ExpectedCompletionTime &&
                                                                                <span title='Expected Time' className='text-warning fs-10'>
                                                                                    {(v.ExpectedCompletionTime) ? <i>{('| ' + v.ExpectedCompletionTime + ' Hour(s) ')}</i> : ''}
                                                                                </span>
                                                                            }

                                                                            {
                                                                                v.Status.toLowerCase() === 'completed' &&
                                                                                <span title='Completed In' className='text-success fs-10'>
                                                                                    {(v?.ProgressBarData?.TotalTimeTaken) ? <i>{('| ' + v.ProgressBarData?.TotalTimeTaken + ' Hour(s) ')}</i> : ''}
                                                                                </span>
                                                                            }
                                                                        </div>
                                                                        <ProgressComp userDuration={v.Duration} expectedDuration={v.ExpectedCompletionTime} currentStatus={v.Status} entries={v.entries} ProgressBarData={v.ProgressBarData} />

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                            </React.Fragment>
                                     })}
                                    </ul>
                                </>
                            }
                        </>
                    }
                    {(taskList?.length == 0) &&
                        <h2 class="text-danger text-center mb-0">Please select user name</h2>
                    }
                </div>
            </div>
            

        </div>
    );
};

const mapStateToProps = ({ data }) => {
    const {
        usersList,
        taskList,
        projectsList,
        refresh
    } = data
    return {
        usersList,
        taskList,
        projectsList,
        refresh
    }
}

const mapDispatchToProps = {
    setUsersList,
    setTasksList,
    setProjectsList
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskList);