import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import axios from '../../../AxiosConfig'
import Accordion from 'react-bootstrap/Accordion';

const ProjectView = ({
    userData,
    dateFilter
}) => {
    const [projectList, setProjectList] = useState([])
    const UserID = (userData) ? userData.ID : null
    useEffect(() => {
        const FromDate = dateFilter
        const ToDate = dateFilter
        axios.get('/attendanceProjectWise/' + UserID + '/' + FromDate + '/' + ToDate).then(res => {
            if (res.data.message === 'Success') {
                console.log(res.data.data)
                setProjectList(res.data.data)
            }
        })
    }, [dateFilter])
    return (
        <div id="appCapsule" class="pt-1">
            <div class="section ps-1 pe-1">
                <div class="row">
                    <div class="col-12 col-md-9 pt-2" style={{backgroundColor: 'rgba(52, 199, 89, 0.31)'}}>
                        <h3 class="text-dark text-center d-flex justify-content-center">ONGOING&nbsp; <span class="badge bg-secondary text-white" style={{height: '18px'}}>4</span></h3>
                        <div class="row">
                            {projectList.map((v,k) => {
                                return (
                                    <>
                                        {v.TaskList.length > 0 &&
                                            <div class="col-12 col-sm-4 col-md-4">
                                                <Accordion style={{background: 'transparent', border: 'none'}}>
                                                    <>
                                                        <div class="card mb-2">
                                                            <div class="card-body p-0">
                                                                <Accordion.Item eventKey={1} class="clockyAcod">
                                                                    <Accordion.Header>
                                                                        <div class="row w-100">
                                                                            <div class="col-12 col-md-12 mb-0 d-flex justify-content-between">
                                                                                <div class="d-flex align-items-center justify-content-between w-100">
                                                                                    <div class="d-flex align-items-center">
                                                                                        <span class="fw-bold d-flex align-items-center"><ion-icon name="bulb-outline" class="fs-6 me-0"></ion-icon> {v.ProjectName}</span>
                                                                                        <span class="badge bg-success text-white" style={{height: '16px', marginLeft: '3px'}}>
                                                                                            2 Ongoing
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-12 col-md-12 d-flex justify-content-between">
                                                                                <div class="d-flex align-items-center">
                                                                                    <span class="fw-bold d-flex align-items-center"><ion-icon name="checkmark-done-outline" class="fs-6 me-0"></ion-icon></span>
                                                                                    <span>
                                                                                        8 / 10
                                                                                    </span>
                                                                                </div>
                                                                                <div class="d-flex align-items-center me-2">
                                                                                    <span class="fw-bold d-flex align-items-center"><ion-icon name="person-outline" class="fs-6 me-0"></ion-icon></span>
                                                                                    <span>
                                                                                        1/3
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-12 col-md-12">
                                                                                <div class="d-flex align-items-center me-2">
                                                                                    <span class="fw-bold d-flex align-items-center"><ion-icon name="timer-outline" class="fs-6 me-0"></ion-icon></span>
                                                                                    <span>
                                                                                        30 hrs 50 mins
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Accordion.Header>
                                                                    <Accordion.Body>
                                                                        <ul class="listview image-listview flush">
                                                                            <li>
                                                                                <div class="item pe-0 ps-0">
                                                                                    <button class="icon-box border-warning me-1 cursor-pointer" style={{minHeight: '45px', minWidth: '45px'}} disabled={true}>
                                                                                        <ion-icon name="play" class="text-warning"></ion-icon>
                                                                                    </button>
                                                                                    <div class="in pb-2" style={{display: 'initial', position: 'relative'}}>
                                                                                        <div class="row d-flex align-items-center">
                                                                                            <div class="col-md-8">
                                                                                                Task Name
                                                                                                <div class="d-flex align-items-center">
                                                                                                    <div class="fs-10"><ion-icon name="person" class="fs-10"></ion-icon> 01.00 hrs</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-md-4 text-end">
                                                                                                <button class="btn border-primary me-1 cursor-pointer" style={{borderWidth: '2px'}} disabled={true}>
                                                                                                    <ion-icon name="checkmark" class="me-0"></ion-icon>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                        <span class="fs-10"  style={{position: 'absolute', bottom: 0}}>2 hours ago</span>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                            </div>
                                                        </div>
                                                    </>
                                                </Accordion>
                                            </div>
                                        }
                                    </>
                                )
                            })}
                        </div>
                    </div>
                    <div class="col-12 col-md-3 pt-2" style={{backgroundColor: 'rgba(236, 68, 51, 0.56)'}}>
                        <h3 class="text-dark text-center d-flex justify-content-center">NO WORK&nbsp; <span class="badge bg-secondary text-white" style={{height: '18px'}}>1</span></h3>
                        <div class="row">
                            {projectList.map((v,k) => {
                                return (
                                    <>
                                        {v.TaskList.length == 0 &&
                                            <div class="col-12 col-sm-12 col-md-12">
                                                <Accordion style={{background: 'transparent', border: 'none'}}>
                                                    <>
                                                        <div class="card mb-2">
                                                            <div class="card-body p-0">
                                                                <Accordion.Item eventKey={1} class="clockyAcod">
                                                                    <Accordion.Header>
                                                                        <div class="row w-100">
                                                                            <div class="col-12 col-md-12 mb-0 d-flex justify-content-between">
                                                                                <div class="d-flex align-items-center justify-content-between w-100">
                                                                                    <div class="d-flex align-items-center">
                                                                                        <span class="fw-bold d-flex align-items-center"><ion-icon name="bulb-outline" class="fs-6 me-0"></ion-icon> {v.ProjectName}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-12 col-md-12 d-flex justify-content-between">
                                                                                <div class="d-flex align-items-center">
                                                                                    <span class="fw-bold d-flex align-items-center"><ion-icon name="checkmark-done-outline" class="fs-6 me-0"></ion-icon></span>
                                                                                    <span>
                                                                                        8 / 10
                                                                                    </span>
                                                                                </div>
                                                                                <div class="d-flex align-items-center me-2">
                                                                                    <span class="fw-bold d-flex align-items-center"><ion-icon name="person-outline" class="fs-6 me-0"></ion-icon></span>
                                                                                    <span>
                                                                                        1/3
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-12 col-md-12">
                                                                                <div class="d-flex align-items-center me-2">
                                                                                    <span class="fw-bold d-flex align-items-center"><ion-icon name="timer-outline" class="fs-6 me-0"></ion-icon></span>
                                                                                    <span>
                                                                                        30 hrs 50 mins
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Accordion.Header>
                                                                    <Accordion.Body>
                                                                        <ul class="listview image-listview flush">
                                                                            <li>
                                                                                <div class="item pe-0 ps-0">
                                                                                    <button class="icon-box border-warning me-1 cursor-pointer" style={{minHeight: '45px', minWidth: '45px'}} disabled={true}>
                                                                                        <ion-icon name="play" class="text-warning"></ion-icon>
                                                                                    </button>
                                                                                    <div class="in pb-2" style={{display: 'initial', position: 'relative'}}>
                                                                                        <div class="row d-flex align-items-center">
                                                                                            <div class="col-md-8">
                                                                                                Task Name
                                                                                                <div class="d-flex align-items-center">
                                                                                                    <div class="fs-10"><ion-icon name="person" class="fs-10"></ion-icon> 01.00 hrs</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-md-4 text-end">
                                                                                                <button class="btn border-primary me-1 cursor-pointer" style={{borderWidth: '2px'}} disabled={true}>
                                                                                                    <ion-icon name="checkmark" class="me-0"></ion-icon>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                        <span class="fs-10"  style={{position: 'absolute', bottom: 0}}>2 hours ago</span>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                            </div>
                                                        </div>
                                                    </>
                                                </Accordion>
                                            </div>
                                        }
                                    </>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ auth }) => {
    const {
        userData
    } = auth
    return {
        userData
    }
}
  
const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectView);