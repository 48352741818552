import React, { Fragment } from 'react'
import { connect } from 'react-redux'

import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { setToken } from './features/auth/authSlice'
import Authentication from './components/Pages/Authentication'
// import Layout from './components/Pages/Layout'
// import Load from './components/Pages/Load'
// import AppInfo from './components/Pages/AppInfo'
import jwt_decode from 'jwt-decode'
// import GetStarted from './components/Pages/GetStarted'
import Header from './components/layout/Header/Header'
// import Footer from './components/layout/Footer/Footer'
// import Summary from './components/Pages/Summary'
import LandingPage from './components/Pages/LandingPage'
import LoginLogoutForm from './components/Pages/LoginLogoutForm'
import TaskForm from './components/Pages/TaskForm'
import AdminTaskList from './components/Pages/AdminTaskList'
import TaskList from './components/Pages/TaskList'
import ClockySidebarLayout from './components/layout/Sidebar/ClockySidebarLayout'
import Support from './components/Pages/Support'
import Dashboard from './components/Pages/Dashboard'
import UserRecord from './components/Pages/UserRecord'

const App = ({
  authToken,
  setToken
}) => {

  const validateToken = () => {
    if (authToken) {
      return true
    } else {
      const token = localStorage.getItem('token')
      const tokenData = (token) ? jwt_decode(token) : false
      if (tokenData && new Date(tokenData.exp * 1000) > new Date()) {
        if (tokenData.hasOwnProperty('ID') && tokenData.hasOwnProperty('RoleID')) {
          setToken({ authToken: token })
          return true
        } else {
          localStorage.clear()
          return false
        }
      } else {
        localStorage.clear()
        return false
      }
    }
  }
  const userLoggedIn = validateToken()
  // console.log(userLoggedIn)
  
  
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
        {!userLoggedIn &&
          <Fragment>
            {/* <Route path="*" element={<LoginLogoutFormView />} /> */}
            
            <Route path="*" element={<Auth />} />
            {/* <Route path="getStarted" element={<GetStartedView />} /> */}
            {/* <Route path="getStartedLogin" element={<GetStartedLoginView />} /> */}
          </Fragment>
        }
        {userLoggedIn &&
          <Fragment>
            <Route path="*" element={<DashboardView />} />
            <Route path="/loginLogout" element={<LoginLogoutFormView />} />
            <Route path="/addTask" element={<TaskFormView />} />
            <Route path="/adminTaskManage" element={<AdminTaskListView />} />
            <Route path="/taskList" element={<TaskListView />} />
            <Route path="/support" element={<SupportView />} />
            <Route path="/landingPage" element={<LandingPageView />} />
            <Route path="/dashboard" element={<DashboardView />} />
            <Route path="/userRecord" element={<UserRecordView />} />
            {/* <Route path="/" element={<AboutApp />} />
            <Route path="getStarted" element={<GetStartedView />} />
            <Route path="appinfo" element={<AboutApp />} />
            <Route path="load" element={<SelectWebsiteList />} />
            <Route path="summary" element={<SummaryView />} />
            <Route path="*" element={<Auth />} /> */}
          </Fragment>
        }
        </Routes>
      </BrowserRouter>
    </div>
  );
}

function Auth() {
  return (
    <div className="App pt-5">
      <Authentication></Authentication>
    </div>
  )
}

// function SelectWebsiteList() {
//   return (
//     <div className="App">
//       <Load></Load>
//     </div>
//   )
// }

// function AboutApp() {
//   return (
//     <div className="App">
//       {/* <Layout> */}
//       <Header></Header>
//         <AppInfo></AppInfo>
//       <Footer></Footer>
//       {/* </Layout> */}
//     </div>
//   )
// }

// function GetStartedView() {
//   return (
//     <div className="App">
//     {/* <Layout> */}
//       <GetStarted></GetStarted>
//     {/* </Layout> */}
//     </div>
//   )
// }

// function SummaryView() {
//   return (
//     <div className="App">
//     <Layout>
//       <Summary></Summary>
//     </Layout>
//     </div>
//   )
// }

function LandingPageView() {
  return (
    <div className="App">
    {/* <Layout> */}
      <LandingPage></LandingPage>
    {/* </Layout> */}
    </div>
  )
}

function DashboardView() {
  return (
    <div className="App">
      <Header />
      <Dashboard></Dashboard>
    </div>
  )
}

function UserRecordView() {
  return (
    <div className="App">
      <Header />
      <UserRecord></UserRecord>
    </div>
  )
}

function LoginLogoutFormView() {
  return (
    <div className="App">
      <ClockySidebarLayout>
        <LoginLogoutForm />
      </ClockySidebarLayout>
    {/* <Layout> */}
    {/* </Layout> */}
    </div>
  )
}

function TaskFormView() {
  return (
    <div className="App">
    {/* <Layout> */}
    <ClockySidebarLayout>
      <TaskForm />
    </ClockySidebarLayout>
    {/* </Layout> */}
    </div>
  )
}

function SupportView() {
  return (
    <div className="App">
    {/* <Layout> */}
    <ClockySidebarLayout>
      <Support />
    </ClockySidebarLayout>
    {/* </Layout> */}
    </div>
  )
}

function AdminTaskListView() {
  return (
    <div className="App">
    {/* <Layout> */}
    <ClockySidebarLayout>
      <AdminTaskList />
    </ClockySidebarLayout>
    {/* </Layout> */}
    </div>
  )
}

function TaskListView() {
  return (
    <div className="App">
    {/* <Layout> */}
    <ClockySidebarLayout>
      <TaskList />
    </ClockySidebarLayout>
    {/* </Layout> */}
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
  const {
    authToken
  } = auth

  return {
    authToken
  }
}

const mapDispatchToProps = {
  setToken
}

export default connect(mapStateToProps, mapDispatchToProps)(App)