import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    leftSidebarVisible: false
}

export const leftSidebarSlice = createSlice({
  name: 'leftSidebar',
  initialState,
  reducers: {
    showLeftSidebar: (state, action) => {
      const {
        leftSidebarVisible
      } = action.payload
      state.leftSidebarVisible = leftSidebarVisible
    },
    hideLeftSidebar: (state, action) => {
        state.leftSidebarVisible = false
      }
  }
})

export const { showLeftSidebar, hideLeftSidebar } = leftSidebarSlice.actions;


export default leftSidebarSlice.reducer
