import React from 'react';
import TaskList from './TaskList';
import Navigation from './smallComponents/Navigation';

const AdminTaskList = () => {
    return (
        <div>
            <span class="d-flex justify-content-center mb-2">
                <Navigation></Navigation>
            </span>
            <div>
                <TaskList userRole={'admin'}></TaskList>
            </div>
        </div>
    );
};

export default AdminTaskList;