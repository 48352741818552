import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import axios from '../../AxiosConfig';
import { connect } from 'react-redux';
import moment from 'moment';
import Moment from 'react-moment';
import 'moment-timezone';
import Navigation from '../Pages/smallComponents/Navigation';
import { setAttendanceList } from '../../features/data/dataSlice';
import { ProgressBar } from 'react-bootstrap';
import { useCalculateTaskTime } from '../../Hooks/useCalculateTaskTime';

const TotalWorkingHours = 9;
const covertToSecondsTWH = TotalWorkingHours * 60 * 60
function compareTimePercentage(givenTimeString) {
    const givenTime = new Date(givenTimeString);

    const currentTime = new Date();

    const givenTimeInSeconds = givenTime.getTime() / 1000;
    const currentTimeInSeconds = currentTime.getTime() / 1000;

    const timeDifference = currentTimeInSeconds - givenTimeInSeconds;
    if (timeDifference < covertToSecondsTWH) {
        const percentage = (timeDifference / covertToSecondsTWH) * 100;
        // console.log(percentage);
        return Math.floor(percentage);
    } else {
        return 100;
    }
}

function CalculateEntries(entries, CheckIn, CheckOut){
    const { changeToLocal } = useCalculateTaskTime();
    const tempEn = JSON.parse(JSON.stringify(entries));

    let prevTaskStatus = null;
    let prevTaskID = null;
    let FromTime = new Date(moment(CheckIn).format('LLL'));
    let ActionArray = [];
    // console.log(fromTime)
    for (let index = 0; index < tempEn.length; index++) {
        const element = tempEn[index];
        const CurrentStatus = element.Status.toLowerCase();

        let ToTime = new Date(changeToLocal(element.DateTime))

        const diff = (ToTime.getTime() / 1000) - (FromTime.getTime() / 1000);
        const percante = Math.floor((diff / covertToSecondsTWH) * 100);
        // console.log(prevTaskID, element.TaskID, prevTaskStatus, CurrentStatus)
        if((prevTaskID !== element.TaskID || (prevTaskID === element.TaskID && ( prevTaskStatus === 'completed' || prevTaskStatus === 'paused') && CurrentStatus === 'ongoing' ))) {
            ActionArray.push({
                'project' : 'IDLE',
                'percante' : percante,
                'ToTime' : ToTime,
                'To' : ToTime.getTime(),
                'FromTime' : FromTime,
                'status' : 'IDLE',
                'From' : FromTime.getTime()
            })
        } else if (prevTaskID === element.TaskID && (prevTaskStatus === 'ongoing' && (CurrentStatus === 'completed' || CurrentStatus === 'paused')) ) {
            ActionArray.push({
                'project' : element.TaskName,
                'percante' : percante,
                'ToTime' : ToTime,
                'status' : CurrentStatus,
                'To' : ToTime.getTime(),
                'FromTime' : FromTime,
                'From' : FromTime.getTime()
            })
        }
        // if (index === tempEn.length - 1 && CurrentStatus === 'ongoing') {
        //     let ToTime = new Date()

        //     const diff = (ToTime.getTime() / 1000) - (FromTime.getTime() / 1000);
        //     const percante = Math.floor((diff / covertToSecondsTWH) * 100);
        //     ActionArray.push({
        //         'project' : element.TaskName,
        //         'percante' : percante,
        //         'status' : CurrentStatus,
        //         'ToTime' : ToTime,
        //         'To' : ToTime.getTime(),
        //         'FromTime' : FromTime,
        //         'From' : FromTime.getTime()
        //     })
        // }

        

        if(CurrentStatus === 'ongoing') {
            let ToTime = new Date()

            const diff = (ToTime.getTime() / 1000) - (FromTime.getTime() / 1000);
            const percante = Math.floor((diff / covertToSecondsTWH) * 100);
            ActionArray.push({
                'project' : element.TaskName,
                'percante' : percante,
                'ToTime' : ToTime,
                'status' : CurrentStatus,
                'To' : ToTime.getTime(),
                'FromTime' : FromTime,
                'From' : FromTime.getTime()
            })
        }
        
        if (index === tempEn.length - 1 && CheckOut) {
            if(CurrentStatus === 'completed' || CurrentStatus === 'paused') {
                console.log(CheckOut);
                let ToTime = new Date(changeToLocal(CheckOut))

                const diff = (ToTime.getTime() / 1000) - (FromTime.getTime() / 1000);
                const percante = Math.floor((diff / covertToSecondsTWH) * 100);
                ActionArray.push({
                    'project' : 'IDLE',
                    'percante' : percante,
                    'ToTime' : ToTime,
                    'status' : CurrentStatus,
                    'To' : ToTime.getTime(),
                    'FromTime' : FromTime,
                    'From' : FromTime.getTime()
                })
            }
        }

        // if(prevTaskID !== element.TaskID) {
        //     const diff = (ToTime.getTime() / 1000) - (FromTime.getTime() / 1000);
        //     const percante = Math.floor((diff / covertToSecondsTWH) * 100);
        //     ActionArray.push({
        //             'project' : 'IDLE',
        //             'percante' : percante,
        //             'ToTime' : ToTime,
        //             'To' : ToTime.getTime(),
        //             'FromTime' : FromTime,
        //             'From' : FromTime.getTime()
        //         })
        // } else if (prevTaskID === element.TaskID && (prevTaskStatus === 'ongoing' && (CurrentStatus === 'completed' || CurrentStatus === 'paused')) ) {
        //     const diff = (ToTime.getTime() / 1000) - (FromTime.getTime() / 1000);
        //     const percante = Math.floor((diff / covertToSecondsTWH) * 100);
        //     ActionArray.push({
        //             'project' : element.TaskName,
        //             'percante' : percante,
        //             'ToTime' : ToTime,
        //             'To' : ToTime.getTime(),
        //             'FromTime' : FromTime,
        //             'From' : FromTime.getTime()
        //         })
        // }

        

        prevTaskID = element.TaskID
        prevTaskStatus = element.Status.toLowerCase();
        FromTime = new Date(changeToLocal(element.DateTime));
    }


    return ActionArray
}

function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

const ProgressBarLoginComponent = ({ TotalTime, CheckIn, TaskEntries, CheckOut, UserName }) => {
    const timeStart = compareTimePercentage(CheckIn);
    console.log(CheckIn, CheckOut, TaskEntries);
    if (!TaskEntries || (TaskEntries && TaskEntries.length === 0)) {
        if (CheckIn) {
            if (timeStart >= 100) {
                return <ProgressBar title='IDLE' striped variant="dark" now="100" key={1} />
            }
            return <ProgressBar title='IDLE' striped animated variant="dark" now={timeStart} key={1} />
        }
        return '--'
    }

    if(TaskEntries && CheckIn === undefined) {
        return '--'
    }
    const progressColors = ['warning', 'secondary', 'primary']
    // console.log('starting')
    const ProgressArray  = CalculateEntries(TaskEntries, CheckIn, CheckOut)
    console.log({ProgressArray, UserName, TaskEntries});
    return   <>
            <ProgressBar>
                {
                    
                    ProgressArray?.map((progress, i) => {
                        return <>
                            {
                                progress.project === 'IDLE' &&
                                <ProgressBar title={`${progress.project} | ${progress.FromTime.getHours()}:${progress.FromTime.getMinutes()} - ${progress.ToTime.getHours()}:${progress.ToTime.getMinutes()}`} variant="light" now={progress.percante} key={i} />
                            }
                            {
                                progress.status === 'ongoing' && 
                                <ProgressBar title={`${progress.project} | ${progress.FromTime.getHours()}:${progress.FromTime.getMinutes()} - ${progress.ToTime.getHours()}:${progress.ToTime.getMinutes()}`} animated variant='success' now={progress.percante} key={i} />
                            }
                            {
                                progress.status !== 'ongoing' && progress.project !== 'IDLE' &&
                                <ProgressBar title={`${progress.project} | ${progress.FromTime.getHours()}:${progress.FromTime.getMinutes()} - ${progress.ToTime.getHours()}:${progress.ToTime.getMinutes()}`} variant={progressColors[getRandomNumber(0,2)]} now={progress.percante} key={i} />
                            }
                        </>
                        
                    })
                }
            </ProgressBar>
            {ProgressArray.length > 0 && ProgressArray[ProgressArray.length - 1].project
            }
        </>
    
}

const LoginLogoutForm = ({
    setAttendanceList,
    attendanceList,
    userData
}) => {
    // console.log(attendanceList)
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [userList, setUserList] = useState(null)
    const [userID, setUserID] = useState(null)
    const [actionType, setActionType] = useState('')
    const [disableSubmitButton, setDisableSubmitButton] = useState(false)
    const [userCurrentLocation, setUserCurrentLocation] = useState('')
    const [latLong, setLatLong] = useState(null)
    const [renderCount, setRenderCount] = useState(0)
    const [successMessage, setSuccessMessage] = useState('');
    const [copyMessage, showCopyMessage] = useState(false)
    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedDay, setSelectedDay] = useState('')
    let navigate = useNavigate()
    const today = new Date()
    const formattedTodaysDate = today.toISOString().split('T')[0]
    useEffect(() => {
        setSelectedDate(formattedTodaysDate)
    }, [formattedTodaysDate])
    const handleSubmitLoginLogout = (userID, actionType, username) => {
        let text = "Hi, " + username + "!!\nAre you sure you want to " + actionType;
        if (window.confirm(text) == true) {
            const localDateTime = new Date();
            const moment = require('moment');
            const date = moment();
            const formattedDate = date.format('YYYY-MM-DD');
            let checkInOut = {
                UserID: userID,
                Date: formattedDate,
                Location: userCurrentLocation,
                Lat: latLong?.lat,
                Long: latLong?.long
            }
            if (checkInOut.Lat && checkInOut.Long) {
                axios.put('/attendance', checkInOut).then(res => {
                    if (res.data.message === 'Success') {
                        // setShowSuccessModal(true)
                        if (actionType === 'login') {
                            localStorage.setItem('loggedIn', true)
                        } else {
                            localStorage.clear()
                        }
                        setRenderCount(renderCount + 1)
                        setTimeout(() => {
                            // setShowSuccessModal(false)
                            setDisableSubmitButton(false)
                            // navigate('/addTask')
                        }, 2000);
                    }
                })
            } else {
                setDisableSubmitButton(false)
                alert('Enable Location to Proceed')
            }
        } else {
            text = "You canceled!";
        }
    }
    const isAfter10AM = (time) => {
        const timeToCheck = moment(time, 'hh:mm A'); // Parse the time using moment
        const tenAM = moment('10:20 AM', 'hh:mm A'); // Define 10 AM using moment
        return timeToCheck.isAfter(tenAM); // Check if the given time is after 10 AM
    };
    useEffect(() => {
        axios.get('/users').then(res => {
            if (res.data.message === 'Success') {
                setUserList(res.data.data)
            }
        })
        axios.get('/attendance/' + ((selectedDate) ? selectedDate : formattedTodaysDate)).then(res => {
            if (res.data.message === 'Success') {
                setAttendanceList({ attendanceList: res.data.data })
            }
        })
        setSelectedDay(moment(selectedDate).format('dddd'))
    }, [renderCount, selectedDate])
    const handleUser = (userID) => {
        setUserID(userID)
    }
    useEffect(() => {
        const fetchLocation = async () => {
            if ('geolocation' in navigator) {
                try {
                    const position = await new Promise((resolve, reject) => {
                        navigator.geolocation.getCurrentPosition(resolve, reject)
                    })

                    const { latitude, longitude } = position.coords

                    const response = await fetch(
                        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
                    )
                    const data = await response.json()
                    const city =
                        data.address.city ||
                        data.address.town ||
                        data.address.village ||
                        data.address.hamlet ||
                        ''
                    const country = data.address.country || ''
                    setUserCurrentLocation({ userCurrentLocation: city })
                    setLatLong({ 'lat': latitude, 'long': longitude })
                } catch (error) {
                    console.error('Error getting location:', error.message)
                }
            } else {
                console.error('Geolocation is not supported by this browser.')
            }
        }
        fetchLocation()
    }, [])

    function calculateAboveEightHours(totalTime, CheckIn) {
        const timeStart = compareTimePercentage(CheckIn);
        if (totalTime === undefined || totalTime === null || totalTime === '') {
            if (CheckIn) {
                // return timeStart
                // console.log(timeStart, CheckIn)
                if (timeStart >= 100) {
                    return ''
                    // <ProgressBar striped variant="success" now="100" key={1} />
                }
                return ''
                // <ProgressBar striped animated variant="primary" now={timeStart} key={1} />
            }
            return '--'
        }
        const durationParts = totalTime.split(' ');
        const hours = parseInt(durationParts[0], 10);
        const minutes = parseInt(durationParts[2], 10);

        const momentDuration = moment.duration({ hours, minutes });

        const eightHours = moment.duration(9, 'hours');

        const isAboveEightHours = momentDuration.asHours() >= eightHours.asHours();

        // if (CheckIn) {
        //     const timeStart = compareTimePercentage(CheckIn);
        //     if (timeStart >= 100) {
        //         return (
        //             <>
        //                 <ProgressBar striped variant="success" now="100" key={1} />
        //                 <span class="text-success">{totalTime}</span>
        //             </>
        //     )
        //     }
        //     return (
        //         <>
        //             <ProgressBar striped variant="danger" now={timeStart} key={1} />
        //             <span class="text-danger">{totalTime}</span>
        //         </>
        //     )

        // }
        if (isAboveEightHours) {
            return <>
                <span class="text-success">{totalTime}</span>
                {/* <ProgressBar striped variant="success" now="100" key={1} /> */}
            </>
        } else {
            return <>
                <span class="text-danger">{totalTime}</span>
                {/* <ProgressBar striped variant="danger" now={timeStart} key={1} /> */}
            </>
        }
    }

    // const copyToClipboard = async (textToCopy) => {
    //     try {
    //         await navigator.clipboard.writeText(textToCopy);
    //         showCopyMessage(true)
    //         setSuccessMessage('Copied!');
    //         setTimeout(() => {
    //             showCopyMessage(false)
    //         }, 800);
    //     } catch (err) {
    //         showCopyMessage(true)
    //         setSuccessMessage('Failed to copy!');
    //         setTimeout(() => {
    //             showCopyMessage(false)
    //         }, 800);
    //     }
    // };

    const handleLocationOnMap = (latLong) => {
        let newLatLong = latLong.split(' ');
        window.open('https://www.google.com/maps/?q=' + newLatLong[0] + ',' + newLatLong[2], '_blank');
    }
    return (
        <>
            <div class="d-flex justify-content-center align-items-center flex-column pe-2 ps-2">
                {/* <img src="https://webweb.ams3.cdn.digitaloceanspaces.com/webweb.svg" alt="" style={{width: '130px'}} /> */}
                {/* <Navigation></Navigation> */}
                <div class="container">
                    <div class="row">
                        {/* <div class="col-12 col-md-6 mb-3">
                            <h2 class="d-flex justify-content-center align-items-center mt-2">Login / Logout</h2>
                            <form class="d-flex flex-column" id="loginLogoutForm" onSubmit={(e) => {handleSubmitLoginLogout(e)}}>

                                <div class="form-group boxed">
                                    <div class="input-wrapper">
                                        <label class="form-label" for="username">Select Name</label>
                                        <select class="form-control form-select" id="username" onChange={(e) => {handleUser(e.target.value)}} required>
                                            <option value="">Select Username</option>
                                            {userList?.map((v, k) => {
                                                return <option value={v.ID}>{v.UserName}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>

                                <div class="form-group boxed">
                                    <div class="input-wrapper">
                                        <label class="form-label" for="action">Action</label>
                                        <select class="form-control form-select" onChange={(e) => {setActionType(e.target.value)}} id="action" required>
                                            <option value="">Select Action</option>
                                            <option value="login">Login</option>
                                            <option value="logout">Logout</option>
                                        </select>
                                    </div>
                                </div>

                                <button class="btn btn-success btn-lg mt-2" type='submit' disabled={disableSubmitButton}>
                                    Proceed
                                </button>
                            </form>
                        </div> */}
                        <div class="col-12 col-md-12">
                            <div class="d-flex justify-content-between">
                                <h3 class="d-flex justify-content-center align-items-center mb-0">
                                    {selectedDay}
                                </h3>
                                {/* <h2 class="d-flex justify-content-center align-items-center mb-0">Attendance</h2> */}
                                <div class="d-flex justify-content-center align-items-center mb-0">
                                    <div class="form-group boxed">
                                        <div class="input-wrapper">
                                            <input type="date" class="form-control-sm form-control pe-1" id="name5" placeholder="Enter your name" max={formattedTodaysDate} value={selectedDate} onChange={(e) => { setSelectedDate(e.target.value) }} autocomplete="off" />
                                            <i class="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive border">
                                {/* {
                                    true ? */}
                                    <ul className='listview simple-listview'>
                                    {/* filter((l) => l.ID === 8) */}
                                        {attendanceList?.map((v, k) => {
                                                const after10AM = isAfter10AM(moment(v.CheckIn).format('hh:mm A'));
                                            return <li key={k}>
                                                <div class="item py-2 w-100">
                                                    <div class="in">
                                                        <div className='text-dark'>
                                                            <div className='d-flex justify-content-between'>
                                                                <div className='TasksInfo'>
                                                                    <div className='d-flex gap-2 mt-1 align-items-center'>
                                                                        <h3 className='mb-0'>{v.UserName}</h3>
                                                                        {(calculateAboveEightHours(v.TotalTime, v.CheckIn, v.TaskEntries) != '' && calculateAboveEightHours(v.TotalTime, v.CheckIn, v.TaskEntries) != '--') &&
                                                                            <span class="badge border-primary">
                                                                                {calculateAboveEightHours(v.TotalTime, v.CheckIn, v.TaskEntries)}
                                                                            </span>
                                                                        }
                                                                        {/* <span class="fs-10 badge badge-secondary w-fit" style={{ 'width': 'fit-content' }}>Project Name</span> */}
                                                                    </div>
                                                                </div>
                                                                <div className='TasksActions d-flex justify-content-end flex-wrap gap-1'>
                                                                    {!v.CheckIn && (userData?.ID === v.ID || userData?.RoleID === 1) &&
                                                                        <>
                                                                            <button class="btn btn-outline-primary btn-sm" onClick={() => { handleSubmitLoginLogout(v.ID, 'login', v.UserName) }}>
                                                                                Log In
                                                                            </button>
                                                                        </>
                                                                    }
                                                                    {(v.CheckIn && !v.CheckOut) && (userData?.ID === v.ID || userData?.RoleID === 1) &&
                                                                        <button class="btn btn-outline-primary btn-sm" onClick={() => { handleSubmitLoginLogout(v.ID, 'logout', v.UserName) }}>Log Out</button>
                                                                    }

                                                                    {(!v.CheckIn && !v.CheckOut) && (userData?.ID === v.ID || userData?.RoleID === 1) && <button className='btn btn-danger btn-sm' disabled>
                                                                        Leave
                                                                    </button> }
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className='d-flex justify-content-between gap-2'>
                                                                    {v.CheckIn &&
                                                                        <div className='d-flex align-items-center'>
                                                                            <span class={(after10AM) ? "text-danger" : "text-success"}>
                                                                                <Moment format="hh:mm A">{v.CheckIn}</Moment>
                                                                            </span>
                                                                            <span class="cursor-pointer fs-10" onClick={() => { handleLocationOnMap(v.CheckInLocation) }}>
                                                                                <ion-icon name="location-outline" class="mt-1 fs-6"></ion-icon>
                                                                            </span>
                                                                            {/* {copyMessage && */}
                                                                            {/* <span class="text-secondary fs-10" onClick={() => {handleLocationOnMap(v.CheckInLocation)}} style={{position: 'absolute', background: 'gainsboro', padding: '0px 5px', borderRadius: '5px'}}>
                                                                                    {successMessage}
                                                                                </span> */}
                                                                            {/* } */}
                                                                        </div>
                                                                    }
                                                                    {(v.CheckIn && v.CheckOut) &&
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>
                                                                                <Moment format="hh:mm A">{v.CheckOut}</Moment>
                                                                            </span>
                                                                            <span class="cursor-pointer" onClick={() => { handleLocationOnMap(v.CheckOutLocation) }}>
                                                                                <ion-icon name="location-outline" class="mt-1 fs-6"></ion-icon>
                                                                            </span>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <ProgressBarLoginComponent UserName={v.UserName} TotalTime={v.TotalTime} CheckOut={v.CheckOut} CheckIn={v.CheckIn} TaskEntries={v.TaskEntries} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            })
                                        }
                                    </ul>
                                    {/* : */}
                                    {/* <table class="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="fw-bold text-nowrap">Username</th>
                                                <th scope="col" class="fw-bold text-nowrap">Check In @</th>
                                                <th scope="col" class="fw-bold text-nowrap">Check Out @</th>
                                                <th scope="col" class="fw-bold text-nowrap">Total Duration (Hrs)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {attendanceList?.map((v, k) => {
                                                const after10AM = isAfter10AM(moment(v.CheckIn).format('hh:mm A'));
                                                return <tr>
                                                    <td class="text-nowrap">{v.UserName}</td>
                                                    <td class="text-nowrap fw-bold">
                                                        {v.CheckIn &&
                                                            <>
                                                                <span class={(after10AM) ? "text-danger" : "text-success"}>
                                                                    <Moment format="hh:mm A">{v.CheckIn}</Moment>
                                                                </span>
                                                                <span class="cursor-pointer" onClick={() => { handleLocationOnMap(v.CheckInLocation) }}>
                                                                    <ion-icon name="location-outline" class="mt-1 fs-6"></ion-icon>
                                                                </span>
                                                            </>
                                                        }
                                                        {!v.CheckIn && (userData?.ID === v.ID || userData?.RoleID === 1) &&
                                                            <>
                                                                <button class="btn btn-outline-primary btn-sm" onClick={() => { handleSubmitLoginLogout(v.ID, 'login', v.UserName) }}>
                                                                    Log In
                                                                </button>
                                                            </>
                                                        }
                                                    </td>
                                                    <td class={(v.CheckOut) ? "text-nowrap fw-bold" : "text-nowrap text-success fw-bold"}>
                                                        {(v.CheckIn && v.CheckOut) &&
                                                            <>
                                                                <span>
                                                                    <Moment format="hh:mm A">{v.CheckOut}</Moment>
                                                                </span>
                                                                <span class="cursor-pointer" onClick={() => { handleLocationOnMap(v.CheckOutLocation) }}>
                                                                    <ion-icon name="location-outline" class="mt-1 fs-6"></ion-icon>
                                                                </span>
                                                            </>
                                                        }
                                                        {(v.CheckIn && !v.CheckOut) && (userData?.ID === v.ID || userData?.RoleID === 1) &&
                                                            <button class="btn btn-outline-primary btn-sm" onClick={() => { handleSubmitLoginLogout(v.ID, 'logout', v.UserName) }}>Log Out</button>
                                                        }
                                                    </td>
                                                    <td class="text-nowrap">
                                                        {calculateAboveEightHours(v.TotalTime, v.CheckIn)}
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                 } */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showSuccessModal} centered onHide={() => { setShowSuccessModal(false) }}>
                <Modal.Body className="bg-success">Form Submitted Successfully!</Modal.Body>
            </Modal>
        </>
    );
};

const mapStateToProps = ({ data, auth }) => {
    const { userData } = auth
    const {
        attendanceList
    } = data
    return {
        userData,
        attendanceList
    }
}

const mapDispatchToProps = {
    setAttendanceList
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginLogoutForm);