import React from 'react'

function Support() {
  return (
    <div className='w-100 h-100'>
        <iframe src='https://support.webweb.ai/' style={{'width' : '100%', height: '100%'}} />
    </div>
  )
}

export default Support