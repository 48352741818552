import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    pinModalVisible: false
}

export const PinModalSlice = createSlice({
  name: 'pinModal',
  initialState,
  reducers: {
    showPinModal: (state, action) => {
      const {
        pinModalVisible
      } = action.payload
      state.pinModalVisible = pinModalVisible
    },
    hidePinModal: (state, action) => {
        state.pinModalVisible = false
    }
  }
})

export const { showPinModal, hidePinModal } = PinModalSlice.actions;


export default PinModalSlice.reducer
