import React from 'react';
import TaskForm from '../../Pages/TaskForm';
import { connect } from 'react-redux';
import { hideAddTaskModal } from '../../../features/modal/AddTaskSlice';
import { Modal } from 'react-bootstrap';
import jwt_decode from 'jwt-decode';

const AddTaskPopup = ({
    addTaskModalVisible,
    hideAddTaskModal,
    authToken
}) => {
    const tokenData = jwt_decode(authToken);
    return (
        <Modal show={addTaskModalVisible} size={'lg'} onHide={() => {hideAddTaskModal()}}>
            <Modal.Header closeButton>
                <Modal.Title className='h5'>Add Task for <span class="fw-bold">{tokenData.UserName}</span></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TaskForm />
            </Modal.Body>
        </Modal>
    );
};

const mapStateToProps = ({ addTask, auth }) => {
    const { 
        addTaskModalVisible
    } = addTask
    const {
        authToken
    } = auth
    return {
        addTaskModalVisible,
        authToken
    }
}

const mapDispatchToProps = {
    hideAddTaskModal
}

export default connect(mapStateToProps, mapDispatchToProps)(AddTaskPopup);