import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import TaskForm from '../TaskForm';
import { Button, Modal } from 'react-bootstrap';
import LoginLogoutForm from '../LoginLogoutForm';

const Navigation = () => {
    const location = useLocation();
    
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show2, setShow2] = useState(false);

    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    return (
        <>
            {/* <ul class="nav nav-tabs lined bg-white" role="tablist" style={{position: 'fixed', top: 0, right: 0, left: 0}}>
                <li class="nav-item">
                    <Link to="/taskList" class={(location.pathname.includes('taskList')) ? "nav-link active" : "nav-link"} role="tab" style={{height: '46px'}}>
                        Task List
                    </Link>
                </li>
                <li class="nav-item">
                    <Link to="/loginLogout" class={(location.pathname.includes('loginLogout')) ? "nav-link active" : "nav-link"} role="tab" style={{height: '46px'}}>
                        Attendance
                    </Link>
                </li>
            </ul> */}
            {/* <li class="nav-item">
                <Link to="/addTask" class={(location.pathname.includes('addTask')) ? "nav-link active" : "nav-link"} role="tab" style={{height: '46px'}}>
                    Add Task
                </Link>
            </li> */}
            
            <Button className='position-fixed b-3 e-3 btn-icon btn-icon-only rounded p-3' style={{'zIndex' : 10}} title='Add Task' variant='primary' onClick={handleShow}>
                <span className='d-flex justify-content-center align-items-center' style={{'height' : '45px'}}>
                    <ion-icon name="add-circle-outline" style={{'fontSize' : '40px'}}></ion-icon>
                </span>
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className='h5'>Add Task</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TaskForm handleClose={handleClose} />
                </Modal.Body>
            </Modal>

            <Button className='position-fixed b-5 e-3 btn-icon btn-icon-only rounded p-3' style={{'zIndex' : 10}} title='Add Task' variant='secondary' onClick={handleShow2}>
                <span className='d-flex justify-content-center align-items-center' style={{'height' : '45px'}}>
                    <ion-icon name="log-in-outline" style={{'fontSize' : '40px'}}></ion-icon>
                </span>
            </Button>
            <Modal show={show2} fullscreen centered onHide={handleClose2}>
                <Modal.Header closeButton>
                    <Modal.Title className='h5'>Attendance</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='modalAttendance'>
                        <LoginLogoutForm handleClose={handleClose2} />
                    </div>
                </Modal.Body>
            </Modal>
            {/* // <div class="d-flex">
            //     <Link to="/loginLogout" class="btn btn-primary me-1">Login / Logout</Link>
            //     <Link to="/addTask" class="btn btn-success me-1">Add / Update Task</Link>
            //     <Link to="/taskList" class="btn btn-secondary me-1">Task List</Link>
            // </div> */}
        </>
    );
};

export default Navigation;